<template>
  <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <br />
  <template v-for="checkbox in checkboxes" v-bind:key="checkbox">
    <div class="item">
      <input type="checkbox" v-bind:id="component_id" v-bind:name="component_meta.variable" v-bind:value="checkbox.id"
        v-model="component_value" @click="input_event" /> {{ checkbox.title }}
    </div>
  </template>
</template>


<script>

export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    let checkboxes = {};

    // 載入組件清單給下拉元件
    if (this.field.component.properties.source_json != '' && this.field.component.properties.source_json != undefined) {
      checkboxes = JSON.parse(this.field.component.properties.source_json);
      for (let key in checkboxes) {
        checkboxes[key] = { 'title': checkboxes[key] };
      }
    }
    else {

      let parameters = '';
      let params = {};

      if (this.field.component.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < this.field.component.properties.sort_rule_fields.length; i++) {
          let variable = this.field.component.properties.sort_rule_fields[i].field_variable;
          let rule = this.field.component.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      if (this.field.component.properties.datazone != '' && this.field.component.properties.datazone != undefined) {
        params['dz'] = this.field.component.properties.datazone;
      }

      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }

      let api_url = this.$microlink.base_api_url + this.field.component.properties.source_api + parameters;

      // 如果有設定快取，則從快取中取得資料
      if (this.field.component.properties.pool == 'Y') {

        let pool_key = this.$microlink.base64_encode(api_url);
        if (this.$microlink.get_data_pool(pool_key) == undefined) {
          checkboxes = this.$microlink.api(api_url);
          checkboxes = checkboxes.data;
          this.$microlink.set_data_pool(pool_key, checkboxes);
        }
        else {
          checkboxes = this.$microlink.get_data_pool(pool_key);
        }
      }
      else {
        checkboxes = this.$microlink.api(api_url);
        checkboxes = checkboxes.data;
      }
    }

    let localValue = this.value;
    if (localValue == undefined) {
      localValue = [];
    }



    return {

      // 元件識別碼
      component_id: 'checkbox-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: this.field,

      // 元件值
      component_value: localValue,

      // 元件選項
      checkboxes: checkboxes,

      // 元件訊息
      component_message: this.message,
    };
  },
  mounted() {

  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;
    }
  },
  methods: {
    input_event: function ($event) {
      if (this.component_value == undefined) {
        this.component_value = [];
      }

      // 如果 this.component_value 陣列中已經有 $event.target.value，則移除
      // 否則加入
      if (this.component_value.indexOf($event.target.value) > -1) {
        this.component_value.splice(this.component_value.indexOf($event.target.value), 1);
      }
      else {
        this.component_value.push($event.target.value);
      }

      $event.temp = this.component_value;
      this.$emit('input', $event)
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

input[type=checkbox] {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

div.item {
  display: inline;
  margin-right: 20px;
}
</style>